@import "variables.scss";
.hero {
    height: 120vh;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: flex-start;
    padding-top: 2rem;
    align-items: center;
    text-align: center;
    color: #33541d;
    font-size: 171px;
    line-height: 140px;
    text-transform: uppercase;
    font-family: var(--glass-antiqua);
    // background-image: url('/images/hero/hero@4x.webp');
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    @media screen and (max-width: calc($desktop-breakpoint + 400px)) {
        font-size: 130px;
        line-height: 105px;
    }
    @media screen and (max-width: calc($desktop-breakpoint)) {
        background-image: url('/images/hero/hero@2x.webp');
        font-size: 120px;
        line-height: 100px;
    }
    @media screen and (max-width: calc(($tablet-breakpoint + $mobile-breakpoint)/2)) {
        min-height: 40px;
        font-size: 80px;
        line-height: 90px;
    }
    @media screen and (max-width: calc($mobile-breakpoint + 20px)) {
        background-image: url('/images/hero/hero.webp');
        aspect-ratio: 320/41;
        min-height: 40px;
        font-size: 68px;
        line-height: 81px;
    }
}

.before {
    font-family: var(--rubik-dirt);
}

.video {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;
}
