@import "variables.scss";
.videoWrapper {
    width: 100%;
    height: 100%;
}

.video {
    width: 100%;
    height: 100%;
}

.playButton {
    position: absolute;
    z-index: 2;
    margin: auto;
    width: 30%;
    height: 30%;
}

.thumbnail {
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
}
