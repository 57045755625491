@import "variables.scss";
.responsiveImageContainer {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: column;
}

.responsiveImage {
    position: relative;
    width: auto;
    object-fit: contain;
    height: 100%;
}
