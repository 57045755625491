@import "variables.scss";
.storyNavigation {
    width: 30%;
    max-width: 350px;
    @media screen and (max-width: $navigation-under-video-breakpoint) {
        width: 100%;
        max-width: 600px;
        padding-top: 1rem;
    }
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    nav {
        height: 100%;
        width: 100%;
    }
    ul {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 26px;
        justify-content: center;
        align-items: center;
        font-family: var(--encode-sans-extra-bold);
        font-size: 30px;
        line-height: 34px;

        text-transform: uppercase;

        @media screen and (max-width: $navigation-under-video-breakpoint) {
            flex-direction: row;
        }
        @media screen and (max-width: 650px) {
            flex-direction: column;
            width: 100%;
        }
        @media screen and (max-width: $mobile-breakpoint) {
            font-size: 17px;
        }
    }
    li:nth-child(1) {
        @include origin-stories;
    }
    li:nth-child(2) {
        @include ice-age-texas;
    }
    li:nth-child(3) {
        @include daily-life;
    }
    li {
        border-radius: 15px;
        min-width: 300px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
        text-align: center;
        &:hover {
            scale: 1.1;
        }

        a {
            display: block;
            padding: 30px 1rem;
            @media screen and (max-width: $mobile-breakpoint) {
                padding: 18px 1rem;
            }
        }
        @media screen and (max-width: $navigation-under-video-breakpoint) {
            min-width: 175px;
        }
        @media screen and (max-width: 650px) {
            width: 100%;
        }
    }
}
